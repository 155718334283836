import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const SideBar = ({ setOpen }) => {

  const history = useHistory();
  const { user, logout } = useAuth();
  const userType = user?.userType ?? ""
  // const userType = JSON.parse(localStorage.getItem("userObj"))?.userType ?? ""
  const handleLogout = () => {
    logout()
  };

  return (
    <>
      {/* outer-footerBottom */}
      <Box minHeight={'100vh'} height={'100%'} sx={{ backgroundColor: '#1a1d26' }} padding={2} position={'relative'}>
        <Grid item container>
          <Grid item xs={12} display={{ xs: 'none', lg: 'block' }}>
            <Typography variant='h6' color={'#fff'}>LOGO</Typography>
          </Grid>
          <Grid item xs={12} pt={{xs: '0', lg: 5}}>
            <List sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
              <ListItem component={'a'}
                onClick={() => { setOpen && setOpen(false); history.push(userType == "superAdmin" ? '/dashboard' : '/company/dashboard') }} sx={{
                  border: history.location.pathname.includes('/dashboard') ? '1px solid #fff' : '1px solid transparent',
                  borderRadius: 2, backgroundColor: history.location.pathname.includes('/dashboard') ? '#1f1d45' : '',
                  color: '#fff',
                  '&:hover': {
                    border: '1px solid #fff',
                    backgroundColor: '#1f1d45',
                  }
                }}>
                <DashboardIcon /> &nbsp; Dashboard
              </ListItem>
              {userType == "superAdmin" &&
                <ListItem component={'a'} onClick={() => { setOpen && setOpen(false); history.push('/companies') }} sx={{
                  border: history.location.pathname.includes('/companies') ? '1px solid #fff' : '1px solid transparent',
                  borderRadius: 2, color: '#fff',
                  backgroundColor: history.location.pathname.includes('/companies') ? '#1f1d45' : '',
                  '&:hover': {
                    border: '1px solid #fff',
                    backgroundColor: '#1f1d45',
                  }
                }}><ApartmentIcon /> &nbsp; Company</ListItem>
              }
              {userType == "company" &&
                <ListItem component={'a'} onClick={() => { setOpen && setOpen(false); history.push('/company/members') }} sx={{
                  border: '1px solid transparent',
                  border: history.location.pathname.includes('/members') ? '1px solid #fff' : '1px solid transparent',
                  borderRadius: 2, color: '#fff',
                  backgroundColor: history.location.pathname.includes('/members') ? '#1f1d45' : '',
                  '&:hover': {
                    border: '1px solid #fff',
                    backgroundColor: '#1f1d45',
                  }
                }}><ApartmentIcon /> &nbsp; Member</ListItem>
              }
            </List>
          </Grid>
          <Grid item width={'100%'} padding={2} position={'absolute'} bottom={0} left={0}>
            <List sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
              <ListItem component={'a'} className={history.location.pathname.includes('/profile') ? 'active' : 'Inactive'}
                onClick={() => { setOpen && setOpen(false); history.push(userType == "superAdmin" ? '/profile' : '/company/profile') }}
                sx={{
                  borderRadius: 2, color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1f1d45',
                  }
                }}><SettingsIcon /> &nbsp; Setting</ListItem>
              <ListItem component={'a'} sx={{
                borderRadius: 2, color: '#fff',
                '&:hover': {
                  backgroundColor: '#1f1d45',
                }
              }} onClick={() => handleLogout()}><LogoutIcon /> &nbsp; Logout</ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};


export default SideBar;
