export const APP_VERSION = '1.0.0';
export const ENV = 'staging';

export const BASE_URL = 'https://bio-backend.teexponent.com/v1/';
// export const BASE_URL_CURL = 'https://v2.convertapi.com/';
export const BASE_URL_CURL = 'https://bio-python.teexponent.com/';
export const ROWS_PER_PAGE =  10

// export const BASE_URL = 'https://1a0d-103-242-196-25.ngrok-free.app/v1/';


