import React, { createContext, useEffect, useReducer } from 'react';
import { login, loginForGoogle, ME, register, GoogleRegister } from '../actions/authAction';
import { useHistory } from 'react-router-dom';
import Cookie from '../utils/Cookie';
import SplashScreen from '../components/SplashScreen';
import ToastHot from 'react-hot-toast';
import CallAPI from '../utils/APICall';
import { jwtDecode } from "jwt-decode"
import { lOGIN_API, ME_API } from '../utils/APIEndPoints';

const initialAuthState = {
  isAuthenticated: false,
  isLogout: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user: user
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'USER_UPDATE': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => { },
  updateUser: () => Promise.resolve(),
  RegisterUser: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username, password) => {
    let body = {
      username: username,
      password: password
    }
    // let body = {
    //   "username": "admin",
    //   "password": "SM7HsvJfZrdH8A!"
    // }
    const response = await CallAPI("POST", lOGIN_API, body);
    if (response.status) {
      localStorage.setItem('accessToken', response.res.data.token);
      localStorage.setItem('accessRole', response.res.data.userOrCompany.userType);
      localStorage.setItem('userObj', JSON.stringify(response.res.data.userOrCompany)); //will be removed after ME integration
      let user = response.res.data.userOrCompany
      if (response.res.data.userOrCompany.userType == "superAdmin") {
        user.totalCompanies = response.res.data.totalCompanies
      }
      dispatch({ type: 'LOGIN', payload: user })
      return { loginData: user, response: true };
    } else {
      return { loginData: response.msg, response: false };
    }

  };

  const logout = async () => {
    sessionStorage.clear();
    Cookie.deleteCookie('_tokenVAS')
    // const response = await CallAPI("PUT", USER_LOGOUT, body);
    // if (response.status) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem("accessToken")
    localStorage.removeItem("userObj") //will be removed after ME integration
    history.push('/login');
    // }
  };

  const updateUser = (user) => {
    dispatch({ type: 'USER_UPDATE', payload: user })
  }

  const RegisterUser = async (payload, type = 'normalRegister') => {
    if (type === 'normalRegister') {
      const response = await register(payload);
      if (response) {
        if (response.data.status === 200) {
          // ToastHot.success("Successfully registered with us");

          // Cookie.setCookie('_tokenVAS', response.data.token, 1);
          // dispatch({type: 'REGISTER', payload: response.data.user_data})
          return response;
        } else {
          return response;
        }
      } else {
        ToastHot.error("Something went wrong");

      }
    } else {
      const response = await GoogleRegister(payload);
      if (response) {
        if (response.data.status === 200) {
          ToastHot.success("Successfully registered with us");
          Cookie.setCookie('_tokenVAS', response.data.user_data.token_data.access_tokenVAS, 1);
          dispatch({ type: 'REGISTER', payload: response.data.user_data.user })
          return response;
        } else {
          return response;
        }
      } else {
        ToastHot.error("Something went wrong");

      }
    }

  };

  const setSession = (accessToken, accessRole) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessRole', accessRole);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessRole', accessRole);
    }
  };

  const isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > Math.ceil(currentTime);
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const accessRole = localStorage.getItem('accessRole');
        if (accessRole && accessToken && isValidToken(accessToken)) {
          setSession(accessToken, accessRole);
          const response = await CallAPI("GET", ME_API);
          let user = response.res.data.userOrCompany;
          if (response.res.data.userOrCompany.userType == "superAdmin") {
            user.totalCompanies = response.res.data.totalCompanies
          }
          if (response.status) {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                isLogout: false,
                user
              }
            });
          } else {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null,
              }
            });
            logout();
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            }
          });
          // if(history.location?.pathname?.includes("login")){

          // }else{
          //   logout();
          // }

        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
        // logout();
      }
    };
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
        RegisterUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
