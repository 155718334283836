/* eslint-disable radix */
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  // console.log("testing", JSON.parse(localStorage.getItem("userObj")))

  // if (localStorage.getItem("accessToken")) {
  if (isAuthenticated && !!user) {
    // if (JSON.parse(localStorage.getItem("userObj"))?.userType == "company") {
    if (user?.userType == "company") {
      return <Redirect to="/company" />;
    }
    // if (JSON.parse(localStorage.getItem("userObj"))?.userType == "member") {
    if (user?.userType == "member") {
      return <Redirect to="/member" />;
    }
    else {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <>
    {!isAuthenticated &&
    <>
      {children}
      </>
}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
