import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import AuthAdminLayout from './layouts/AuthAdminLayout';
import AuthCompanyLayout from './layouts/AuthCompanyLayout';

import AdminAuthGuard from './guards/AdminAuthGuard';
import ComapnyAuthGuard from './guards/ComapnyAuthGuard';
import GuestGuard from './guards/GuestGuard'

import SplashScreen from './components/SplashScreen';
import SiteLayout from './layouts/SiteLayout';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<SplashScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/404'))
  },
  {
    exact: true,
    path: '/uploads/profiles/:id'
  },
  {
    exact: true,
    path: '/uploads/idproofs/:id'
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: SiteLayout,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: SiteLayout,
    path: '/login',
    component: lazy(() => import('./views/Guest/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: SiteLayout,
    path: '/reset-password',
    component: lazy(() => import('./views/Guest/ChangePassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: SiteLayout,
    path: '/member-verification',
    component: lazy(() => import('./views/Guest/MemberVerification'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/identify',
    layout: SiteLayout,
    component: lazy(() => import('./views/Guest/Identify'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: SiteLayout,
    path: '/register',
    component: lazy(() => import('./views/Guest/Register'))
  },

  {
    path: '/company',
    guard: ComapnyAuthGuard,
    layout: AuthCompanyLayout,
    routes: [
      {
        exact: true,
        path: '/company',
        component: () => <Redirect to="/company/profile" />
      },
      {
        exact: true,
        path: '/company/profile',
        component: lazy(() => import('./views/Auth/AuthCompany/Profile'))
      },
      {
        exact: true,
        path: '/company/dashboard',
        component: lazy(() => import('./views/Auth/AuthCompany/Dashboard'))
      },
      {
        exact: true,
        path: '/company/members',
        component: lazy(() => import('./views/Auth/AuthCompany/Members'))
      },
      {
        exact: true,
        path: '/company/account',
        component: lazy(() => import('./views/Auth/AuthCompany/Account'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '*',
    layout: AuthAdminLayout,
    routes: [
      {
        exact: true,
        guard: AdminAuthGuard,
        path: '/dashboard',
        component: lazy(() => import('./views/Auth/Admin/Dashboard'))
      },
      {
        exact: true,
        guard: AdminAuthGuard,
        path: '/companies',
        component: lazy(() => import('./views/Auth/Admin/Companies'))
      },
      {
        exact: true,
        guard: AdminAuthGuard,
        path: '/profile',
        component: lazy(() => import('./views/Auth/Admin/Profile'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
];

export default routes;
