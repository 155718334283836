import React from 'react';
import {
  Box,
  LinearProgress,
} from '@mui/material';

const SlashScreen = () => {

  return (
    <div>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default SlashScreen;
