import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import useAuth from '../hooks/useAuth';
import Cookie from '../utils/Cookie';
import { Avatar, Box, Button, Grid, IconButton, Menu, MenuItem, Typography,  } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import SideBar from './SideBar';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';




function SiteHeader() {
  const history = useHistory()
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [sidebar, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div className="authHeader">
      <Box sx={{ backgroundColor: '#1a1d26' }} padding={2}>
        <Grid item container justifyContent={'space-between'}>
          <Grid item>
            <Box display={{xs:'inline-flex', lg:'none'}} alignItems={'center'}>
              <IconButton  sx={{color:'#fff', }} onClick={toggleDrawer(true)}><MenuOpenIcon sx={{color:'#fff'}}/></IconButton>
              <Typography variant='h6' color={'#fff'} display={'inline-flex'} ml={1.5}>LOGO</Typography>
            </Box>
            <Drawer open={sidebar} onClose={toggleDrawer(false)}>
              <SideBar setOpen={setOpen}/>
            </Drawer>
          </Grid>
          <Grid item>
            <Grid item container>
              {/* <Avatar /> */}
              <Box>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={() => history.push(user.userType == "superAdmin" ? '/profile' : '/company/profile')}
                  sx={{color:'#fff',backgroundColor:'transparent !important'}}
                >
                  {JSON.parse(localStorage.getItem("userObj"))?.userType == "company" ? JSON.parse(localStorage.getItem("userObj"))?.companyName :"Admin"}
                  {/* {user?.userType == "company" ? user?.companyName :"Admin"} */}
                </Button>
                {/* <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

SiteHeader.propTypes = {};

export default SiteHeader;
