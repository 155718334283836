import React from 'react';
import PropTypes from 'prop-types';
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import SlashScreen from '../../components/SplashScreen';
import { Grid } from '@mui/material';
import useAuth from '../../hooks/useAuth';

const SiteLayout = ({ children }) => {
    const { isAuthenticated, user } = useAuth();

    if (isAuthenticated && !!user) {
        return <SlashScreen />;
    }
    return (
        <main>
            {children}
        </main>
    );
};

SiteLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SiteLayout;
