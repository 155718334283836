/* eslint-disable radix */
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AdminAuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  // console.log("testing", JSON.parse(localStorage.getItem("userObj")))

  // if (!localStorage.getItem("accessToken")) {
  if (!isAuthenticated && user === null) {
    return <Redirect to="/login" />;
  }
  // else if (JSON.parse(localStorage.getItem("userObj"))?.userType == "company"){
  else if (isAuthenticated && user?.userType == "company") {
    return <Redirect to="/company/dashboard" />;
  }
  // else if (JSON.parse(localStorage.getItem("userObj"))?.userType == "member"){
  else if (isAuthenticated && user?.userType == "member") {
    return <Redirect to="/member/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AdminAuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminAuthGuard;
