
import axios from 'axios';
import { BASE_URL, BASE_URL_CURL } from '../../src/constants'

export function CallAPI(method, url, body = '', headercustom=null, endpoint) {
  let apiUrl = ''
  if(endpoint=='curl')
  {
    apiUrl = BASE_URL_CURL+url
  }
  else
  {
    apiUrl = BASE_URL+url
  }
  console.log("headercustom-----", headercustom)
    headercustom = !!headercustom ? { headers: { Authorization: `Bearer ${headercustom}` } } : localStorage.getItem('accessToken') ? { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } } : null
  return new Promise(async (resolve, reject) => {
    try {
        if (method === "POST") {
          axios
            .post(apiUrl, body, headercustom)
            .then((response) => {
              console.log("response POST--", response)
              if (response.status === 200 || response.status === 201) {
                resolve({ status: true, res: response, msg: response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.message });
              }
            })
            .catch((err) => {
              console.log("error POST--", err)
              resolve({ status: false, res: err?.response, msg: err?.response?.data?.message });
            });

        }
        if (method === "PUT") {
          axios
            .put(apiUrl, body, headercustom)
            .then((response) => {
              console.log("response PUT--", response)
              if (response.status === 200 || response.status === 201) {
                resolve({ status: true, res: response, msg: response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.message });
              }
            })
            .catch((err) => {
              console.log("error PUT--", err)
              resolve({ status: false, res: err?.response, msg: err?.response?.data?.message });
            });
        }
        if (method === "PATCH") {
          axios
            .patch(apiUrl, body, headercustom)
            .then((response) => {
              console.log("response PATCH--", response)
              if (response.status === 200 || response.status === 201) {
                resolve({ status: true, res: response, msg: response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.message });
              }
            })
            .catch((err) => {
              console.log("error PATCH--", err)
              resolve({ status: false, res: err?.response, msg: err?.response?.data?.message });
            });
        }
        if (method === "GET") {
          axios
            .get(apiUrl, headercustom)
            .then((response) => {
              console.log("response GET--", response)
              if (response.status === 200 || response.status === 201) {
                resolve({ status: true, res: response, msg: response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.message });
              }
            })
            .catch((err) => {
              resolve({ status: false, res: err?.response, msg: err?.response?.data?.message });
            });
        }
        if (method === "DELETE") {
          let header = headercustom
          if (body) {
            header = { ...headercustom, data: body }
          }
          axios
            .delete(apiUrl, header)
            .then((response) => {
              console.log("response DELETE--", response)
              if (response.status === 200 || response.status === 201) {
                resolve({ status: true, res: response, msg: response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.message });
              }
            })
            .catch((err) => {
              console.log("error DELETE--", err)
              resolve({ status: false, res: err?.response, msg: err?.response?.data?.message });
            });
        }
    } catch (err) {
      reject(err.response);
    }
  });
}

export default CallAPI;
