import React from 'react';
import PropTypes from 'prop-types';
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import SideBar from '../../components/SideBar';
import { Grid } from '@mui/material';

const AuthAdminLayout = ({ children }) => {


  return (
    <main>
      <div className="authLogin">
        <Grid container>
          <Grid item width={{xs:250,lg:300}} display={{xs:'none', lg:'block'}}>
            <SideBar />
          </Grid>
          <Grid item width={{xs:1, lg:'calc(100% - 300px)'}}>
            <Grid item container>
              <Grid item xs={12}>
                <SiteHeader />
              </Grid>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>



        {/* <SiteFooter /> */}
      </div>
    </main>
  );
};

AuthAdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthAdminLayout;
