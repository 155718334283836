import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/JWTAuthContext';
import { GlobalStoreProvider } from './contexts/GlobalStoreContext';
import routes, { renderRoutes } from './routes';
import "bootstrap/dist/css/bootstrap.min.css";
import './scss/main.scss';
import { SnackbarProvider } from 'notistack';

import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <SnackbarProvider dense maxSnack={3}>
      <AuthProvider>
        <GlobalStoreProvider>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              className: 'hot-toast-opening-class',
              duration: 3000,
              success: {
                duration: 3000,
              },
            }}
          />
          <Switch>
            {renderRoutes(routes)}
          </Switch>
        </GlobalStoreProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
